
<template>
  <div class="fragment">
    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            :backgroundImage="'order-consolidation'"
            :name="$t('consolidations_consolidation.localization_value.value')"
            :value="'#' + consolidation.data.id"
        >
          <template slot="button">
            <div class="site-link brown text-decoration-underline mt-4 noPrintable">
              <div v-if="isAdmin && consolidation.data.historyOfChanged"
                   class="mb-3"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
                <div class="site-link text-decoration-underline btn-style brown"
                     @click="historyOfChangesFunc('consolidation', consolidation.data.historyOfChanged)">
                  {{$t('common_CheckHistory.localization_value.value')}}
                </div>
              </div>
            </div>
<!--            <div v-if="isAdmin" class="d-flex flex-wrap noPrintable">-->
<!--              <div class="mt-4 mr-2"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate([-->
<!--                'consolidations_print',-->
<!--                ])"></div>-->
<!--                <MainButton-->
<!--                    class="btn-fit-content"-->
<!--                    :value="$t('consolidations_print.localization_value.value')"-->
<!--                    @click.native="$emit('print')"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">
        <CardRightBlock>
          <template slot="header">
            <div class="order-create__head mb-3 noPrintable">
              <div class="order-create__head-line">
                <div class="order-create__header-col"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                  'consolidations_backTo',
                  ])"></div>
                  <router-link :to="$store.getters.GET_PATHS.ordersConsolidation" class="order-create__head-back">
                    <LinkBack
                        :value="$t('consolidations_backTo.localization_value.value')"
                    />
                  </router-link>
                </div>
                <div class="order-create__header-col justify-content-end d-flex">
                </div>
              </div>

              <div
                  v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                  'consolidations_showConsolidationTNT',
                  'consolidations_showConsolidationDHL',
                  'consolidations_showConsolidationFedex',
                  'consolidations_newConsolidationYourCity',
                ])"></div>
                <div class="order-create__head-title color-orange"
                     v-if="isTNT">
                  {{$t('consolidations_showConsolidationTNT.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-red"
                     v-if="isDHL">
                  {{$t('consolidations_showConsolidationDHL.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isFedex">
                  {{$t('consolidations_showConsolidationFedex.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isDHL_USA">
                  {{$t('consolidations_newConsolidationDHLUSA.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isDPD">
                  {{$t('consolidations_newConsolidationEu.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isEUProduct">
                  {{$t('consolidations_newConsolidationEuProducts.localization_value.value')}}
                </div>
                <div class="order-create__head-title color-blue"
                     v-if="isFromYourCity">
                  {{$t('consolidations_newConsolidationYourCity.localization_value.value')}}
                </div>
              </div>
            </div>
          </template>

          <template slot="body">
            <div class="order-create__section">

              <div class="order-create__title-horizontal noPrintable"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['fbm_PersonalInformation'])"></div>
                <TitleHorizontal
                    :value="$t('fbm_SendFromInformation.localization_value.value')"
                    :rightBtn="false"
                    :rightBtnType="'edit'"
                    :rightBtnText="$t('common_edit.localization_value.value')"
                    @rightBtnClick="$emit('changeStep', 1)"
                />
              </div>

              <div class="order-create__row custom-row noPrintable">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50 mb-3">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                                          <div class="admin-edit" @click="editTranslate(['common_name'])"></div>
                          {{$t('common_name.localization_value.value')}}
                        </div>
                        <div class="block-table__content word-break">
                          <ValueHelper
                              :value="consolidation.data.sendFrom"
                              :deep="'first_name'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['common_surName'])"></div>
                          {{$t('common_surName.localization_value.value')}}
                        </div>
                        <div class="block-table__content word-break">
                          <ValueHelper
                              :value="consolidation.data.sendFrom"
                              :deep="'last_name'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20 block-table__col--sm-50">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate(['profile_companyName'])"></div>
                          {{$t('profile_companyName.localization_value.value')}}
                        </div>
                        <div class="block-table__content word-break">
                          <ValueHelper
                              :value="consolidation.data.sendFrom"
                              :deep="'company_name'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="order-create__row custom-row noPrintable">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="section-label">
                      <template v-if="consolidation.getConsolidationDestination()">
                        <template v-if="consolidation.getWarehouse()">
                         {{ consolidation.getWarehouse().currentTranslate.name }} —
                        </template>
                        {{ consolidation.getConsolidationDestination().currentTranslate.name }}
                      </template>
                    </div>
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_shippingDate',
                          ])"></div>
                          {{$t('consolidations_shippingDate.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getShippedAt()">
                            {{ consolidation.getShippedAt() | moment("DD MMM, YYYY") }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_received',
                          ])"></div>
                          {{$t('consolidations_received.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getReceivedAt()">
                            {{ consolidation.getReceivedAt() | moment("DD MMM, YYYY") }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div v-if="!isTNT" class="block-table__col block-table__col--20">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_trackingNumber',
                          ])"></div>
                          {{$t('consolidations_trackingNumber.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getTrackingNumber()">
                            {{ consolidation.getTrackingNumber() }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20" v-if="consolidation.getDeliveryService()">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_shippingCompany',
                          ])"></div>
                          {{$t('consolidations_shippingCompany.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getDeliveryService()">
                            {{ consolidation.getDeliveryService().name }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="isTNT" class="order-create__row custom-row noPrintable">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label block-table__label--mh48"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_DeliveryDate',
                          ])"></div>
                          {{$t('consolidations_DeliveryDate.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getDeliveryDate()">
                            {{ consolidation.getDeliveryDate() | moment("DD MMM, YYYY") }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label block-table__label--mh48"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_TimeGap',
                          ])"></div>
                          {{$t('consolidations_TimeGap.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getTimeCourierFrom()">
                            {{ consolidation.getTimeCourierFrom() | moment("HH:mm") }} - {{ consolidation.getTimeCourierTo() | moment("HH:mm") }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label block-table__label--mh48"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_lunchPeriod',
                          ])"></div>
                          {{$t('consolidations_lunchPeriod.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getTimeNotAvailableFrom()">
                            {{ consolidation.getTimeNotAvailableFrom() | moment("HH:mm") }} - {{ consolidation.getTimeNotAvailableTo() | moment("HH:mm") }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="order-create__title-horizontal noPrintable"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'consolidations_consolidation',
                          ])"></div>
                <TitleHorizontal
                    v-if="!isTNT"
                    :value="$t('consolidations_consolidation.localization_value.value')"
                />
              </div>

              <div class="order-create__row custom-row noPrintable">
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_dimensions',
                          ])"></div>
                          {{$t('consolidations_dimensions.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          {{consolidation.data.Dimensions.data.dimensionLength}}x
                          {{consolidation.data.Dimensions.data.width}}x
                          {{consolidation.data.Dimensions.data.height}}, {{consolidation.data.Dimensions.data.weight}}kg
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_shippingCost',
                          ])"></div>
                          {{$t('consolidations_shippingCost.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getShippingCost()">
                            ${{ consolidation.getShippingCost() }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20" v-if="isEUProduct">
                        <div class="block-table__label">
                          {{$t('consolidations_vatCost.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.data.vatAmountAdminChanged === 1">
                            {{consolidation.data.vatAmount}}
                          </template>
                          <template v-else>
                            ${{getVatCost()}}
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--40">
                        <div class="block-table__label"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                          <div class="admin-edit" @click="editTranslate([
                          'consolidations_comment',
                          ])"></div>
                          {{$t('consolidations_comment.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getComment()">
                            {{ consolidation.getComment() }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="(isFedex || isDHL_USA) && isFromAPI">
                <div class="order-create__section-label section-label noPrintable"
                     v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                    'consolidations_documents',
                  ])"></div>
                  {{$t('consolidations_documents.localization_value.value')}}
                </div>

                <div class="order-create__row custom-row noPrintable">
                  <div class="order-create__col custom-col mb-3"
                       v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate([
                      'consolidations_toxicLabel',
                      'consolidations_toxicLabelHelp',
                      'common_DropBallToxicSubstance',
                    ])"></div>
                    <DefaultCheckbox
                        :label="$t('consolidations_toxicLabel.localization_value.value')"
                        v-model="consolidation.data.toxicSubstance"
                        :disabled="true"
                    >
                      <template slot="content">
                        <v-popover
                            class="site-tooltip mt--1"
                            :disabled="!tooltipAddProductActive"
                            offset="5"
                            placement="top"
                            trigger="hover"

                        >
                          <span>
                            <TooltipBtn/>
                          </span>
                          <template slot="popover">
                            <p>
                              <b>{{$t('consolidations_toxicLabel.localization_value.value')}}</b>
                            </p>
                            <p>
                              {{$t('consolidations_toxicLabelHelp.localization_value.value')}}
                            </p>
                          </template>
                        </v-popover>
                      </template>
                    </DefaultCheckbox>
                  </div>
                  <div class="order-create__col custom-col mb-3">
                    <DefaultCheckbox
                        :label="$t('express_DropBallTest.localization_value.value')"
                        v-model="consolidation.data.dropCertification"
                        :disabled="true"
                    >
                      <template slot="content">
                        <v-popover
                            class="site-tooltip mt--1"
                            :disabled="!tooltipAddProductActive"
                            offset="5"
                            placement="top"
                            trigger="hover"

                        >
                          <span>
                            <TooltipBtn/>
                          </span>
                          <template slot="popover">
                            <p>
                              <b>{{$t('express_DropBallTest.localization_value.value')}}</b>
                            </p>
                            <p>
                              {{$t('consolidations_toxicLabelHelp.localization_value.value')}}
                            </p>
                          </template>
                        </v-popover>
                      </template>
                    </DefaultCheckbox>
                  </div>

                </div>
              </template>

              <div v-if="!isEUProduct && !isUserHasEasyOrder" class="custom-row noPrintable">
                <div class="custom-col">

                  <div class="block-table__row ml-0 mr-0 mt-4">
                    <div class="block-table__col p-0">
                      <div class="block-table__label"
                           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                        <div class="admin-edit" @click="editTranslate([
                          'consolidations_ordersFBMInc',
                          'consolidations_number',
                          'consolidations_name',
                          'consolidations_deliveryService',
                          'consolidations_trackingNumber',
                          ])"></div>
                        {{$t('consolidations_ordersFBMInc.localization_value.value')}}
                      </div>
                      <div class="block-table__content mt-2">

                        <div class="site-table-wrap table-small"
                             v-if="!isMobileFunc()"
                        >
                          <table class="site-table">
                            <thead>
                            <tr>
                              <th class="text-lg-right">#</th>
                              <th>{{$t('consolidations_number.localization_value.value')}}</th>
                              <th class="white-space-line">{{$t('consolidations_name.localization_value.value')}}</th>
                              <th>{{$t('consolidations_deliveryService.localization_value.value')}}</th>
                              <th>{{$t('consolidations_trackingNumber.localization_value.value')}}</th>
                            </tr>
                            </thead>
                            <tbody>

                            <tr
                                v-for="(item, index) in consolidation.getRelatedOrders()"
                                :key="index"
                            >
                              <td class="white-space-line" align="right" width="40px">
                                <b>{{index + 1}}</b>
                              </td>
                              <td>
                                <b>#{{item.id}}</b>
                              </td>
                              <td>
                                <div class="table-row ellipsis">
                                  {{item.recipient_contact_name}}
                                </div>
                              </td>
                              <td>
                                <div>
                                  <ValueHelper
                                      :value="item"
                                      :deep="'delivery_method.name'"
                                  />
                                </div>
                              </td>
                              <td>
                                <div>
                                  <ValueHelper
                                      :value="item"
                                      :deep="'tracking_number'"
                                  />
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>

                        </div>

                        <div class="site-table-mobile"
                             v-if="isMobileFunc()"
                        >
                          <div class="site-table-mobile__inner custom-row">
                            <div
                                class="custom-col custom-col--50 custom-col--sm-100"
                                v-for="(item, index) in consolidation.getRelatedOrders()"
                                :key="index"
                            >
                              <div class="site-table-mobile__item">
                                <div class="site-table-mobile__head">
                                  {{item.recipient_contact_name}}
                                </div>
                                <div class="site-table-mobile__row custom-row">
                                  <div class="site-table-mobile__piece custom-col custom-col--33">
                    <span>
											#{{index + 1}}
										</span>
                                  </div>
                                  <div class="site-table-mobile__piece custom-col custom-col--66">
                                    {{$t('consolidations_number.localization_value.value')}}
                                    <span>
											#{{item.id}}
										</span>
                                  </div>
                                </div>
                                <div v-if="item.delivery_method" class="site-table-mobile__row custom-row">
                                  <div class="site-table-mobile__piece custom-col">
                                    <span>
                                      <ValueHelper
                                          :value="item"
                                          :deep="'delivery_method.name'"
                                      />
                                    </span>
                                  </div>
                                </div>
                                <div class="site-table-mobile__piece custom-col">
                                  {{$t('consolidations_trackingNumber.localization_value.value')}}
                                  <div>
                                    <ValueHelper
                                        :value="item"
                                        :deep="'tracking_number'"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <template v-if="consolidation.data.proformProductsForTable.length > 0 && !isUserHasEasyOrder">

                <div class="order-create__title-horizontal noPrintable"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate([
                          'consolidations_products',
                          ])"></div>
                  <TitleHorizontal
                      :value="$t('consolidations_products.localization_value.value')"
                  />
                </div>

                <div class="order-create__row custom-row noPrintable">
                  <div class="order-create__col custom-col">

                    <div class="block-table__name block-table__name--mobile  mb-4"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate([
                          'consolidations_newProducts',
                          ])"></div>
                      {{ $t('consolidations_newProducts.localization_value.value') }}
                    </div>

                    <div class="custom-row">
                      <div class="custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                           v-for="(item, index) in consolidation.data.proformProductsForTable"
                           :key="index"
                      >
<!--                        <pre>{{item}}</pre>-->
                        <div class="confirm-product-card">
                          <div class="confirm-product-card__items">
                            <div class="confirm-product-card__item" v-if="item.product.proformData.proformCategory !== ''">
                              <div class="confirm-product-card__item-name"
                                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                                <div class="admin-edit" @click="editTranslate([
                          'proform_category',
                        ])"></div>
                                {{$t('proform_category.localization_value.value')}}
                              </div>
                              <div class="confirm-product-card__item-val">
                                {{item.product.proformData.proformCategory.translationStorage.en.name}}
                              </div>
                            </div>

                            <template v-if="localProformTypes = item.product.proformData.proformTypes">
                              <template v-for="(itemType, index) in $store.getters.getProformProductInitialize.proformTypes">
                                <div class="confirm-product-card__item" :key="index">
                                  <div class="confirm-product-card__item-name">
                                    {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_Ua'].name }}
                                  </div>
                                  <div class="confirm-product-card__item-val">
                                    {{localProformTypes[itemType.id].typeValue !== '' ?
                                      localProformTypes[itemType.id].typeValue.value.translationStorage.ua.name : ''}}
                                  </div>
                                </div>
                                <div class="confirm-product-card__item" :key="index + '2'">
                                  <div class="confirm-product-card__item-name">
                                    {{localProformTypes[itemType.id].typeName[itemType.translationStorage.en.name + '_En'].name }}
                                  </div>
                                  <div class="confirm-product-card__item-val">
                                    {{localProformTypes[itemType.id].typeValue !== '' ?
                                      localProformTypes[itemType.id].typeValue.value.translationStorage.en.name : ''}}
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>

                          <div class="confirm-product-card__item"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate([
							'express_Price',
						])"></div>
                            <div class="confirm-product-card__item-name">
                              {{$t('express_Price.localization_value.value')}}
                            </div>
                            <div class="confirm-product-card__item-val">
                              {{item.product.price}}
                            </div>
                          </div>

                          <div class="confirm-product-card__item"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate([
							'consolidations_Quantity',
						])"></div>
                            <div class="confirm-product-card__item-name">
                              {{$t('consolidations_Quantity.localization_value.value')}}
                            </div>
                            <div class="confirm-product-card__item-val">
                              {{item.product.itemsQuantity}}
                            </div>
                          </div>

                          <div class="confirm-product-card__bottom" v-if="item.product.handMade"
                               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                            <div class="admin-edit" @click="editTranslate([
                          'product_Handmade',
                        ])"></div>
                            <StatusIcoBtn :type="'handmade'" class="mr-2"/>
                            {{$t('product_Handmade.localization_value.value')}}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="block-table__total site-line-total mb-2"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate([
                          'consolidations_totallyPerOrder',
                          ])"></div>
                      {{ $t('consolidations_totallyPerOrder.localization_value.value') }}
                      <span>${{getTotalPerOrder}}</span>
                    </div>
                  </div>
                </div>

              </template>


              <div class="custom-row" v-if="_.has(consolidation.data.orderWarehouse, 'name') && !isDPD">
                <div class="custom-col">
                  <div class="d-flex align-center" style="height: 42px;">
                    <RadioDefault
                        class="white-space-line width-initial mr-3"
                        :label="consolidation.data.orderWarehouse.name"
                        :name="'warehouse'"
                        :value="true"
                        :disabled="true"
                    />
                  </div>
                </div>
              </div>

<!--              v-if="!isDPD"-->
              <div class="order-create__row custom-row">
                <div class="order-create__col custom-col">


                  <template v-if="consolidation.data.productsForCellArray.length > 0 && consolidation.data.productsForCellArray[0].data.ProformProduct.product.productObject !== '' ">
                    <div class="block-table__name block-table__name--mobile  mb-4"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate([
                          'consolidations_productsFromWarehouse',
                          'consolidations_Quantity',
                          ])"></div>
                      {{ $t('consolidations_productsFromWarehouse.localization_value.value') }}
                    </div>


                    <div class="site-table-wrap table-small"
                         v-if="!isMobileFunc()"
                    >
                      <table class="site-table">
                        <thead>
                        <tr>
                          <th>SKU</th>
                          <th class="forPrint" v-if="isAdmin">{{$t('fba_Cells.localization_value.value')}}</th>
                          <th class="text-lg-right">{{$t('consolidations_Quantity.localization_value.value')}}</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr
                            v-for="(item, index) in consolidation.data.productsForCellArray"
                            :key="index"
                        >
                          <td>
                            {{item.data.ProformProduct.product.productObject.name_for_fbm}}
                          </td>
                          <td class="forPrint" v-if="isAdmin">
                            <template v-if="cells = getCellsInTable(item, true)">
                              <div v-for="(itemText, indexText) in cells.text" :key="indexText">
                                {{itemText}}
                              </div>
                            </template>
                          </td>
                          <td align="right">{{item.data.ProformProduct.product.itemsQuantity}}</td>
                        </tr>
                        </tbody>
                      </table>

                    </div>

                    <div class="site-table-mobile"
                         v-if="isMobileFunc()"
                    >
                      <div class="site-table-mobile__inner custom-row">
                        <div
                            class="custom-col custom-col--50 custom-col--sm-100"
                            v-for="(item, index) in consolidation.data.productsForCellArray"
                            :key="index"
                        >
                          <div class="site-table-mobile__item">
                            <div class="site-table-mobile__head">
                              {{item.data.ProformProduct.product.productObject.name_for_fbm}}
                            </div>
                            <div class="site-table-mobile__row custom-row">
                              <div v-if="isAdmin" class="site-table-mobile__piece custom-col custom-col--33 forPrint">
                                {{$t('fba_Cells.localization_value.value')}}
                                <span>
                                  <template v-if="cells = getCellsInTable(item, true)">
                                    <div v-for="(itemText, indexText) in cells.text" :key="indexText">
                                      {{itemText}}
                                    </div>
                                  </template>
                                </span>
                              </div>
                              <div class="site-table-mobile__piece custom-col custom-col--33">
                                {{$t('consolidations_Quantity.localization_value.value')}}
                                <span>
											{{item.data.ProformProduct.product.itemsQuantity}}
										</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                </div>
              </div>

              <div class="order-create__title-horizontal noPrintable"
                   v-if="isFromAPI"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'consolidations_senderInfo',
                          'common_name',
                          'common_phone',
                          'consolidations_address',
                          'common_city',
                          'common_region',
                          'common_country',
                          'common_zipCode',
                          ])"></div>
                <TitleHorizontal
                    :value="$t('consolidations_senderInfo.localization_value.value')"
                />
              </div>

              <div class="order-create__row custom-row noPrintable"
                   v-if="isFromAPI"
              >
                <div class="order-create__col custom-col">
                  <div class="order-create__table block-table">
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('common_name.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <template v-if="consolidation.getSenderContactName()">
                            {{ consolidation.getSenderContactName() }}
                          </template>
                          <template v-else>
                            —
                          </template>
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('common_phone.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="'data.User.user.user_personal_contact.phone'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('consolidations_address.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="consolidation.data.User.user.use_alternative_address ? 'data.User.user.user_alternative_contact.address' :
                                'data.User.user.user_personal_contact.address'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--40">
                        <div class="block-table__label">
                          {{$t('common_city.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="consolidation.data.User.user.use_alternative_address ? 'data.User.user.user_alternative_contact.city' :
                                'data.User.user.user_personal_contact.city'"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="block-table__row">
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('common_region.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="consolidation.data.User.user.use_alternative_address ? 'data.User.user.user_alternative_contact.region.name' :
                                'data.User.user.user_personal_contact.region.name'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('common_country.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="consolidation.data.User.user.use_alternative_address ? 'data.User.user.user_alternative_contact.country.name' :
                                'data.User.user.user_personal_contact.country.name'"
                          />
                        </div>
                      </div>
                      <div class="block-table__col block-table__col--20">
                        <div class="block-table__label">
                          {{$t('common_zipCode.localization_value.value')}}
                        </div>
                        <div class="block-table__content">
                          <ValueHelper
                              :value="consolidation"
                              :deep="consolidation.data.User.user.use_alternative_address ? 'data.User.user.user_alternative_contact.zip' :
                                'data.User.user.user_personal_contact.zip'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

<!--              <div class="order-create__title-horizontal">-->
<!--                <TitleHorizontal-->
<!--                    :value="$t('consolidations_shipping.localization_value.value')"-->
<!--                />-->
<!--              </div>-->

<!--              <div class="order-create__row custom-row">-->
<!--                <div class="order-create__col custom-col">-->
<!--                  &lt;!&ndash;        <PriceCardBig&ndash;&gt;-->
<!--                  &lt;!&ndash;            class="big"&ndash;&gt;-->
<!--                  &lt;!&ndash;            :value="'$32'"&ndash;&gt;-->
<!--                  &lt;!&ndash;            :text="'Cost of Delivery'"&ndash;&gt;-->
<!--                  &lt;!&ndash;        />&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
            </div>

          </template>

          <template slot="footer">
            <div class="order-create__footer noPrintable">
              <div class="order-create__footer-link"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'common_cancel',
                          ])"></div>
                <router-link :to="$store.getters.GET_PATHS.ordersConsolidation"
                             class="site-link site-link--alt"
                >
                  {{$t('common_cancel.localization_value.value')}}
                </router-link>
              </div>

              <div class="order-create__footer-btn ml-auto">

              </div>
            </div>
          </template>
        </CardRightBlock>
      </div>
    </div>
  </div>
</template>

<script>
  import {ORDERS_CONSOLIDATION_TYPE} from "../../../../../../staticData/staticVariables";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  // import ConsolidationTNT from "./ConsolidationTNT/ConsolidationTNT";
  // import ConsolidationFedex from "./ConsolidationFedex/ConsolidationFedex";
  // import ConsolidationDHL from "./ConsolidationDHL/ConsolidationDHL";
  import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
  import TitleHorizontal from "../../../../../coreComponents/TitleHorizontal/TitleHorizontal";
  import StatusIcoBtn from "../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {consolidationMixinHelper} from "../../../../../../mixins/consolidationMixins/consolidationMixinHelper";
  import ValueHelper from "../../../../../coreComponents/ValueHelper/ValueHelper";
  import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  // import MainButton from "../../../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "ConsolidationDetailUser",
    components: {
      // MainButton,
      RadioDefault,
      TooltipBtn,
      DefaultCheckbox,
      ValueHelper,
      StatusIcoBtn,
      TitleHorizontal,
      LinkBack,
      CardRightBlock,
      CardLeftBlock
      // ConsolidationTNT,
      // ConsolidationDHL,
      // ConsolidationFedex,
    },

    mixins: [
      mixinDetictingMobile,
      consolidationMixinHelper
    ],

    props: {
      consolidation: Object,
    },

    data() {
      return {
        ORDERS_CONSOLIDATION_TYPE: ORDERS_CONSOLIDATION_TYPE,
        tooltipAddProductActive: true,
      }
    },

    mounted(){
      console.log(this.$route.params.type);
    },

    computed: {
      getTotalPerOrder: function () {
        let totalPrice = 0
        this.consolidation.data.proformProductsForTable.forEach(item => {
          totalPrice += (item.product.itemsQuantity * item.product.price) || 0
        })

        return totalPrice.toFixed(2)
      },
    },

    methods: {
      getCellsInTable(item, forPrint = false) {
        let text = ''
        if (forPrint) {
          text = []
        }
        let count = -1

        let UserProductCellArray = item.data.UserProductCellArray,
            UserMixedProductCellArray = item.data.UserMixedProductCellArray

        let UserCellArray = item.data.UserCellArray
        let FreeCellArray = item.data.FreeCellArray
        let MixedCellArray = item.data.MixedCellArray

        let comboCellArraysArray = [
          UserProductCellArray,
          UserMixedProductCellArray,
          UserCellArray,
          FreeCellArray,
          MixedCellArray
        ]

        comboCellArraysArray.forEach(cellArray => {
          cellArray.map(cellItem => {
            if (cellItem.data.cellCount > 0) {
              let cellItemSelected = cellItem.data.cellSelected
              if (forPrint) {
                text.push(cellItemSelected?.combo_name)
              }
              else {
                count === -1 ? text = cellItemSelected?.combo_name : null
              }
              count ++
            }
          })
        })

        return {text, count}
      },
    },

  }
</script>

<style scoped>
  .forPrint {
    display: none;
  }

  .block-table__label--mh48 {
    min-height: 48px;
  }

  @media (min-width: 993px) {
    .progress-step-shipments {
      max-width: 520px;
    }
  }

  @media (max-width: 1120px) {

    .detail-page__right {
      min-height: calc(100vh - 250px);
    }
  }
</style>
